<template>
  <div class="wrap" ref="appRef">
    <div class="ctn">
      <div class="left">
        <img src="@/assets/logo.png" alt>
      </div>
      <div class="right">
        <div class="form-box">
          <div class="titles-box">
            <div class="welcome">欢迎登录 Welcome to login</div>
            <div class="desc">OEE在线统计管理系统</div>
            <div class="desc">OEE online statistical management system</div>
          </div>
          <div class="input-box">
            <img class="icon" src="@/assets/img/login/icon1.png" alt>
            <a-input class="inp" placeholder="请输入用户名 Please enter username" v-model="username"/>
          </div>
          <div class="input-box">
            <img class="icon" src="@/assets/img/login/icon2.png" alt>
            <a-input class="inp" placeholder="请输入密码 Please enter password" v-model="pwd"/>
          </div>
          <div class="checkbox">
            <a-checkbox :checked="checkNick" @change="handleChange">记住密码 Remember the password</a-checkbox>
          </div>
          <a-button class="login-btn" @click="handleSubmit()">登录 Login</a-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import drawMixin from "../utils/drawMixin";
import { login } from "@/api/login";
export default {
  mixins: [drawMixin],
  data() {
    return {
      username: "",
      pwd: "",
      checkNick: false
    };
  },
  mounted() {
    clearInterval();
    //验证判断是否记住密码
    if (localStorage.getItem("pwd") && localStorage.getItem("userName")) {
      this.checkNick = true;

      this.username = localStorage.getItem("userName");
      this.pwd = localStorage.getItem("pwd");
    } else {
      this.checkNick = false;
      this.username = "";
      this.pwd = "";
    }
    this.$store.commit("setStartTimes", "");
    this.$store.commit("setEndTimes", "");
    this.$store.commit("setSearchType",0);
  },
  methods: {
    handleChange(e) {
      this.checkNick = e.target.checked;
    },
    handleSubmit() {
      if (!this.username) {
        this.$message.error("请输入正确的用户名 Please enter the correct username");
        return false;
      }
      if (!this.pwd) {
        this.$message.error("请输入正确的密码 Please enter the correct password");
        return false;
      }
      // 判断是否记住密码
      let params = {
        UserName: this.username,
        Password: this.pwd
      };
      login(params).then(res => {
        if (res.Code == 0) {
          // localStorage.setItem("Access-Token", res.Data.Token);
          let token_code="EECC136E-24DE-461C-B977-A95DE53BC508-root-20240304235011"
          localStorage.setItem("Access-Token", token_code);
          //验证判断是否记住密码
          if (this.checkNick) {
            localStorage.setItem("pwd", this.pwd);
            localStorage.setItem("userName", this.username);
          } else {
            localStorage.setItem("pwd", "");
            localStorage.setItem("userName", "");
          }
          this.$router.push({
            path: "/"
          });
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.wrap {
  width: 1920px;
  height: 1080px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transform-origin: left top;
  overflow: hidden;
  background-image: url("../assets/img/login/login_bg.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  .ctn {
    display: flex;
    // align-content: center;
    // justify-content: center;
  }
  .left {
    flex: 1;
    height: 1080px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .right {
    width: 580px;
    height: 1080px;
    background: #fff;
    display: flex;
    justify-content: center;
    // align-items: center;
    background-image: url("../assets/img/login/login_bg2.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: 28px -46px;
    border-radius: 50px 0px 0px 50px;
    box-shadow: -18px 1px 30px 7px rgba(0, 0, 0, 0.2);
    .form-box {
      width: 100%;
      padding: 290px 88px 0;
      overflow: hidden;
      .titles-box {
        margin: 0 0 36px;
        width: 430px;
        //background-color: #f36f40;
        overflow: hidden;
        .welcome {
          width: 360px;
          //background-color: #8840f3;
          height: 38px;
          line-height: 38px;
          white-space: nowrap;
          color: #3f4653;
          font-weight: bold;
          font-size: 26px;
          background-image: url("../assets/img/login/login_title_bg.png");
          background-repeat: no-repeat;
          background-size: 306px 12px;
          background-position: 25px bottom;
        }
        .desc {
          height: 40px;
          width: 430px;
          //background-color: #6af340;
          padding: 8px 0 0 0;
          line-height: 30px;
          color: #5b677c;
          font-weight: bold;
          font-size: 20px;
          letter-spacing: 0.02em;
        }
      }
      .input-box {
        display: flex;
        width: 400px;
        height: 50px;
        box-sizing: border-box;
        position: relative;
        border: 1px solid #abaeb5;
        border-radius: 6px;
        margin: 0 0 30px 0;
        .icon {
          position: absolute;
          display: block;
          width: 22px;
          height: 22px;
          left: 20px;
          top: 14px;
          z-index: 3;
        }
        .inp {
          height: 100%;
          width: 100%;
          font-size:16px;
          padding-left: 62px;
          border-radius: 6px;
        }
      }
      .checkbox {
        padding: 0 0 66px;
        color: #abaeb5;
        font-size: 18px;
      }
    }
    .login-btn {
      width: 400px;
      height: 56px;
      font-size: 20px;
      color: #fff;
      border-radius: 6px;
      background: #f36f40;
      box-shadow: 0px 4px 14px 0px rgba(243, 111, 64, 0.66);
    }
  }
  ::v-deep .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #f36f40;
    border-color: #f36f40;
  }
}
</style>